import { storage } from '@/services/firebase'

export const deleteFile = async (file, path) => {
    try {
        const storageRef = storage().ref(path)
        const profilePicRef = storageRef.child(file)
        await profilePicRef.delete()
    } catch (error) {
        throw new Error('File could not be deleted')
    }
}
