import { storage } from '@/services/firebase'

export const saveFile = async (file, path) => {
    try {
        const storageRef = storage().ref(path)
        const docRef = storageRef.child(file.name)
        var metadata = {
            customMetadata: {
                filename: file.name,
            },
        }
        await docRef.put(file, metadata)
    } catch (error) {
        throw new Error('FILE ERROR!')
    }
}
