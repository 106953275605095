import { storage } from '@/services/firebase'

export const openFile = async (file, path) => {
    try {
        const storageRef = storage().ref(path)
        const documentRef = storageRef.child(file)
        var url = ''
        await documentRef
            .getDownloadURL()
            .then(res => {
                url = res
            })
            .catch(err => {
                url = ''
                console.log(err)
            })
        setTimeout(() => {
            if (url !== '') {
                window.open(url, '_blank')
            }
        })
    } catch (error) {
        throw new Error('FILE ERROR!')
    }
}
